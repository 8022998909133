export const joy = [
    {
        text: 'Let’s start with a question',
        duration: 4000,
        setModus: 'calming'
    },
    {
        text: 'What memory brings you joy?',
        duration: 180000
    },
];
