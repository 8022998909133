import React from 'react'
import { Grid } from '@material-ui/core'
import { Title, Text, SubTitle } from '../../components/styled'

const CreditsSection = ({ small }) => {

    return (
        <div style={{ width: '100vw', height: '100vh', backgroundColor: 'black' }}>
            <Grid style={{ padding: small ? 30 : 100, height: '100vh' }} alignItems='center' justify='center' container direction='row'>
                <Grid style={{ zIndex: 3, textAlign: 'center', }} item md={12} lg={8} xl={8}>
                    <SubTitle>
                        Credits
                    </SubTitle>
                    {small ?
                        <SubTitle>
                            Development — Thomas Gertenbach, Sunny de Graaf Created by — Pablo Marin
                        </SubTitle>
                        :
                        <Title>
                            Development — Thomas Gertenbach, Sunny de Graaf Created by — Pablo Marin
                        </Title>
                    }
                    <Grid container style={{ justifyContent: 'center' }}>
                        <Grid item xs={6} md={4}>
                            <Text>
                                <a href='https://www.instagram.com/meet.ohm/'>Instagram</a>
                            </Text>
                        </Grid>
                        <Grid item xs={6} md={4}>
                            <Text>
                                <a href='https://github.com/sunnydegraaf/ohm/'>Github</a>
                            </Text>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

export default CreditsSection
