import React, { useState, useEffect } from 'react';
import { useTransition, a, useSpring } from 'react-spring'
import { Typography } from '@material-ui/core';
import useSound from 'use-sound';
import bowlSfx from '../sounds/Sound-bowl.mp3';
import { useStore, useBreathing, useStretching } from '../store';

function Text({ text }) {
  const [moodIndex, setMoodIndex] = useState(1)
  const { setBreath } = useBreathing()
  const { setPosition } = useStretching()
  const { setPhase, phase, setModus, setBreathDurarion, mood, sound } = useStore()
  let [time, setTime] = useState(30)
  let [textDissapear, setTextDissapear] = useState(false)
  let [textIndex, setTextIndex] = useState(0);
  let textData = text
  const [playGong] = useSound(bowlSfx, {
    volume: sound ? 0.28 : 0
  });

  useEffect(() => {
    if (textIndex <= textData.length) {
      const timeout = setTimeout(() => {
        if (textIndex + 1 !== textData.length) {
          setTextIndex(textIndex += 1)
          textData[textIndex].noText ? setTextDissapear(true) : setTextDissapear(false)
        }

        else if (phase === 'welcome') {
          const interval = setInterval(() => {
            if (time !== -1) setTime(time--)
            else {
              playGong()
              setPhase(mood[0])
              clearInterval(interval)
            }
          }, 1000);
          clearTimeout(timeout)
        }
        else {
          if (mood.length !== moodIndex && phase !== 'end') {
            setTextIndex(0)
            playGong()
            setPhase(mood[moodIndex])
            setMoodIndex(moodIndex + 1)
            clearTimeout(timeout)
          } else if (phase !== 'end') {
            setTextIndex(0)
            playGong()
            setPhase('end')
            clearTimeout(timeout)
          }
        }

      }, textData[textIndex].duration);
    }
  }, [textIndex])

  useEffect(() => {

    if (textData[textIndex].setModus) setModus(textData[textIndex].setModus)

    if (phase === 'breathing' || phase === 'stretching' || phase === 'focusing') {
      if (textData[textIndex].direction === 'Breath in') {
        setBreath('in')
        setBreathDurarion(4000)
      } else if (textData[textIndex].direction === 'Hold your breath') {
        setBreath('hold')
        setBreathDurarion(2000)
      } else if (textData[textIndex].direction === 'Breath out') {
        setBreathDurarion(6000)
        setBreath('out')
      } else if (textData[textIndex].direction === 'Front') {
        setPosition('front')
        setModus('activating')
      } else if (textData[textIndex].direction === 'Left') {
        setPosition('left')
        setModus('activating')
      } else if (textData[textIndex].direction === 'Right') {
        setPosition('right')
        setModus('activating')
      } else if (textData[textIndex].direction === 'Ceiling') {
        setPosition('ceiling')
        setModus('activating')
      } else if (textData[textIndex].direction === 'Floor') {
        setPosition('floor')
        setModus('activating')
      } else if (textData[textIndex].direction === 'If your thoughts wander') {
        setModus('extra-activating')
      } else if (textData[textIndex].playGong) {
        setPosition('frontBig')
        setModus('activating')
        playGong()
      }
    }
  }, [textIndex, phase, playGong, setBreath, setBreathDurarion, setModus, setPosition, textData])


  const propsCount = useSpring({
    opacity: textIndex + 1 === textData.length ? 1 : 0,
    display: textIndex + 1 === textData.length && phase === 'welcome' ? 'inline' : 'none'
  })

  const transitions = useTransition(textData[textIndex], item => item.text, {
    from: {
      textAlign: 'center',
      position: 'absolute',
      opacity: 0,
      display: 'table',
      width: '100%',
      height: '100%',
      transform: 'translateY(-40px)',
      zIndex: 5,
    },
    enter: { opacity: 1, transform: 'translateY(0px)' },
    leave: { opacity: 0, transform: 'translateY(40px)' },
    config: {
      mass: 20,
      tension: 418,
      friction: 180
    }
  })

  return transitions.map(({ item, props, key }) => {
    return (
      <a.div key={key} style={props}>
        {!textDissapear &&
          <Typography style={{ fontSize: 'calc(1.3rem + 0.6vw)', fontWeight: 400, display: 'table-cell', verticalAlign: 'middle', fontFamily: 'Graphik' }}>
            {item.text} <br />
            <a.span style={propsCount}>{time}</a.span>
          </Typography>
        }
      </a.div >
    )
  }
  )
}

export default Text;