import React, { useEffect } from 'react'
import { Grid, useTheme, useMediaQuery } from '@material-ui/core'
import Logo from '../components/Logo';
import { Text } from '../components/styled';
import { useStore } from '../store';
import { Link } from 'react-router-dom';
import Fade from 'react-reveal/Fade';

const SettingPage = () => {
    const themes = useTheme();
    const small = useMediaQuery(themes.breakpoints.down('sm'));
    const { setPhase } = useStore()


    useEffect(() => {
        setTimeout(() => {
            setPhase('sacrifice')
        }, 6000);
    }, [setPhase])

    return (
        <Grid style={{ padding: small ? 30 : 60, height: '100vh' }} container>
            <Grid item xs={12}>
                <Grid container justify='space-between'>
                    <Logo style={{ height: '150px' }} small={small} dark />
                    <Link style={{ textDecoration: 'none' }} to='/'>
                        <Text style={{ margin: 0, cursor: 'pointer' }} color='black'>Back</Text>
                    </Link>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid justify='center' alignItems='center' container>
                    <Grid item xs={8}>
                        <Fade bottom delay={500} duration={2000} distance={'1rem'} opposite>
                            <Text textAlign='center' color=' black'>
                                For a more immersive experience, sit in a dark, quiet room,
                                connect your device to your TV and enable sound.
                            </Text>
                        </Fade>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default SettingPage
