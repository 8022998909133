import React, { useEffect, useRef } from 'react';
import { useSpring, a, config } from 'react-spring'
import { useStore, useBreathing, useStretching } from '../store';
import { useTheme, useMediaQuery } from '@material-ui/core';

function Orb({ getModus, nonInteractive, type, hoverOrb }) {
    const theme = useTheme()
    const small = useMediaQuery(theme.breakpoints.down('sm'));
    const canvasRefA = useRef()
    const canvasRefB = useRef()
    const { volume, phase, breathDuration } = useStore()
    const { breath } = useBreathing()
    const { position } = useStretching()

    const modus = useRef()

    useEffect(() => {
        if (getModus === 'activating') {
            modus.current = 'activating'
        } else if (getModus === 'calming') {
            modus.current = 'calming'
        } else if (getModus === 'extra-calming') {
            modus.current = 'extra-calming'
        } else if (getModus === 'extra-activating') {
            modus.current = 'extra-activating'
        }
    }, [getModus])

    const circleCount = 20;
    const circlePropCount = 8;
    const circlePropsLength = circleCount * circlePropCount;
    const baseSpeed = 0.5;
    const rangeSpeed = 0;
    const baseTTL = 100;
    const rangeTTL = 500;
    const baseRadius = 200;
    const rangeRadius = 120;
    const backgroundColor = 'rgba(0,0,0,1)';

    let canvas;
    let ctx;
    let circleProps;
    let baseHue;

    const { PI, cos, sin, abs, random } = Math;
    const TAU = 2 * PI;
    const rand = n => n * random();
    const fadeInOut = (t, m) => {
        let hm = 0.5 * m;
        return abs((t + hm) % m - hm) / (hm);
    };

    function initCircles() {
        circleProps = new Float32Array(circlePropsLength);
        baseHue = 120;

        let i;

        for (i = 0; i < circlePropsLength; i += circlePropCount) {
            initCircle(i);
        }
    }

    function initCircle(i) {
        let x, y, t, speed, vx, vy, life, ttl, radius, hue;
        x = rand(canvas.a.width);
        y = rand(canvas.a.height);
        t = rand(TAU);
        speed = baseSpeed + rand(rangeSpeed);
        vx = speed * cos(t);
        vy = speed * sin(t);
        life = 0;
        ttl = baseTTL + rand(rangeTTL);
        radius = baseRadius + rand(rangeRadius);


        if (modus.current === 'calming') {
            hue = Math.random() >= 0.5 ? baseHue * (0.1 * 10) : baseHue * (0.2 * 10)
        } else if (modus.current === 'extra-calming') {
            hue = baseHue * (0.1 * 10)
        } else if (modus.current === 'activating') {
            hue = Math.random() >= 0.5 ? baseHue * (0.25 * 10) : baseHue * (0.3 * 10)
        } else if (modus.current === 'extra-activating') {
            hue = baseHue * (0.3 * 10)
        }

        circleProps.set([x, y, vx, vy, life, ttl, radius, hue], i);
    }

    function updateCircles() {
        let i;

        // baseHue++;

        for (i = 0; i < circlePropsLength; i += circlePropCount) {
            updateCircle(i);
        }
    }

    function updateCircle(i) {
        let i2 = 1 + i, i3 = 2 + i, i4 = 3 + i, i5 = 4 + i, i6 = 5 + i, i7 = 6 + i, i8 = 7 + i;
        let x, y, vx, vy, life, ttl, radius, hue;


        x = circleProps[i];
        y = circleProps[i2];
        vx = circleProps[i3];
        vy = circleProps[i4];
        life = circleProps[i5];
        ttl = circleProps[i6];
        radius = circleProps[i7];
        hue = circleProps[i8];
        drawCircle(x, y, life, ttl, radius, hue);

        life++;

        circleProps[i] = x + vx;
        circleProps[i2] = y + vy;
        circleProps[i5] = life;

        (checkBounds(x, y, radius) || life > ttl) && initCircle(i);
    }

    function drawCircle(x, y, life, ttl, radius, hue) {
        ctx.a.save();
        ctx.a.fillStyle = `hsla(${hue},100%,50%,${fadeInOut(life, ttl)})`;
        ctx.a.beginPath();
        ctx.a.arc(x, y, radius, 0, TAU);
        ctx.a.fill();
        ctx.a.closePath();
        ctx.a.restore();
    }

    function checkBounds(x, y, radius) {
        return (
            x < -radius ||
            x > canvas.a.width + radius ||
            y < -radius ||
            y > canvas.a.height + radius
        );
    }

    function createCanvas(canvasRefA, canvasRefB) {
        // container = document.querySelector('.ohm-main--canvas');
        canvas = {
            a: canvasRefA.current,
            b: canvasRefB.current
        };
        // container.appendChild(canvas.b);
        ctx = {
            a: canvas.a.getContext('2d'),
            b: canvas.b.getContext('2d')
        };
    }

    function resize() {
        const { innerWidth, innerHeight } = window;

        canvas.a.width = innerWidth;
        canvas.a.height = innerHeight;

        ctx.a.drawImage(canvas.b, 0, 0);

        canvas.b.width = innerWidth;
        canvas.b.height = innerHeight;

        ctx.b.drawImage(canvas.a, 0, 0);
    }

    function render() {
        ctx.b.save();
        ctx.b.filter = 'blur(150px)';
        ctx.b.drawImage(canvas.a, 0, 0);
        ctx.b.restore();
    }

    function draw() {
        ctx.a.clearRect(0, 0, canvas.a.width, canvas.a.height);
        ctx.b.fillStyle = backgroundColor;
        ctx.b.fillRect(0, 0, canvas.b.width, canvas.b.height);
        updateCircles();
        render();
        window.requestAnimationFrame(draw);
    }


    useEffect(() => {
        createCanvas(canvasRefA, canvasRefB);
        resize();
        initCircles();
        draw();
    }, [])

    function getSize() {
        if (breath) {
            switch (breath) {
                case 'in':
                    return `${(small ? 20 : 40)}vh`
                case 'hold':
                    return `${(small ? 20 : 40)}vh`
                case 'out':
                    return `${(small ? 40 : 65)}vh`
                default:
                    break;
            }
        } else {
            return `${(small ? 40 : 65)}vh`
        }
    }

    function getTransform() {
        if (type === 'hover') return hoverSpring.transform
        else return volumeSpring.transform
    }

    function getPosition() {
        if (position) {
            switch (position) {
                case 'front':
                    return `scale(${volume / 30 + 0.5}) translate(0%,0%)`
                case 'frontBig':
                    return `scale(${volume / 30 + 1}) translate(0%,0%)`
                case 'left':
                    return `scale(${volume / 30 + 0.5}) translate(-350%,0%)`
                case 'right':
                    return `scale(${volume / 30 + 0.5}) translate(350%,0%)`
                case 'ceiling':
                    return `scale(${volume / 30 + 0.5}) translate(0%,-350%)`
                case 'floor':
                    return `scale(${volume / 30 + 0.5}) translate(0%,350%)`
                default:
                    break;
            }
        } else {
            return `scale(${volume / 30 + 1}) translate(0%,0%)`
        }
    }

    const volumeSpring = useSpring({
        transform: phase !== 'stretching' ? `scale(${volume / 30 + 1}) translate(0%,0%)` : getPosition(),
        config: {
            mass: 20,
            tension: 432,
            friction: 180
        }
    })

    const breathingSpring = useSpring({
        height: getSize(),
        width: getSize(),
        config: breath ? { duration: breathDuration } : config.default
    })

    const hoverSpring = useSpring({
        transform: hoverOrb ? 'scale(0.7)' : 'scale(0)',
        config: {
            duration: 1000
        }
    })

    return (
        <div style={{
            position: 'absolute',
            display: 'flex',
            height: '100%',
            width: '100%',

            justifyContent: 'center',
            alignItems: 'center',
            filter: 'saturate(10) blur(100px)',
            zIndex: 5
        }}>
            <canvas
                style={{
                    position: 'absolute',
                    display: 'none',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    opacity: 0,
                    zIndex: -1,
                    transition: 'transform 2s'
                }}
                ref={canvasRefA}
            >
            </canvas>
            <a.canvas
                style={{
                    height: nonInteractive ? `${(small ? 40 : 65)}vh` : breathingSpring.height,
                    width: nonInteractive ? `${(small ? 40 : 65)}vh` : breathingSpring.width,
                    transform: getTransform(type),
                    top: 0,
                    left: 0,
                    clipPath: 'circle(50% at 50% 50%)',
                }}
                ref={canvasRefB}
            />
        </div>
    );
}

export default Orb 