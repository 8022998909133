export const end = [
    {
        text: 'Good job',
        duration: 2000
    },
    {
        text: 'Now it’s time to feed our minds',
        duration: 5000
    },
    {
        text: 'Take as much time as you need',
        duration: 4000
    },
    {
        text: 'If you are ready, you may go back online',
        duration: 5000
    },
    {
        text: '',
        duration: 3000
    },
];
