
import React, { useEffect, useCallback } from 'react'
import { useStore } from '../store';

window.AudioContext = window.AudioContext || window.webkitAudioContext;

let meter
var mediaStreamSource = null;

const MicrophoneListener = ({ audioContext }) => {
    const { setVolume } = useStore()


    const drawLoop = useCallback((time) => {
        setVolume(Math.round(meter.volume * 500 * 1.4))
        requestAnimationFrame(drawLoop);
        return meter.volume * 500 * 1.4
    }, [setVolume])


    const createAudioMeter = useCallback((audioContext, clipLevel, averaging, clipLag) => {
        var processor = audioContext.createScriptProcessor(512);
        processor.onaudioprocess = volumeAudioProcess;
        processor.clipping = false;
        processor.lastClip = 0;
        processor.volume = 0;
        processor.clipLevel = clipLevel || 0.98;
        processor.averaging = averaging || 0.95;
        processor.clipLag = clipLag || 750;

        // this will have no effect, since we don't copy the input to the output,
        // but works around a current Chrome bug.
        processor.connect(audioContext.destination);

        processor.checkClipping =
            function () {
                if (!this.clipping)
                    return false;
                if ((this.lastClip + this.clipLag) < window.performance.now())
                    this.clipping = false;
                return this.clipping;
            };

        processor.shutdown =
            function () {
                this.disconnect();
                this.onaudioprocess = null;
            };

        return processor;
    }, [])

    function volumeAudioProcess(event) {
        var buf = event.inputBuffer.getChannelData(0);
        var bufLength = buf.length;
        var sum = 0;
        var x;

        // Do a root-mean-square on the samples: sum up the squares...
        for (var i = 0; i < bufLength; i++) {
            x = buf[i];
            if (Math.abs(x) >= this.clipLevel) {
                this.clipping = true;
                this.lastClip = window.performance.now();
            }
            sum += x * x;
        }

        // ... then take the square root of the sum.
        var rms = Math.sqrt(sum / bufLength);

        // Now smooth this out with the averaging factor applied
        // to the previous sample - take the max here because we
        // want "fast attack, slow release."
        this.volume = Math.max(rms, this.volume * this.averaging);
    }

    const gotStream = useCallback((stream) => {

        mediaStreamSource = audioContext.createMediaStreamSource(stream);
        meter = createAudioMeter(audioContext);
        mediaStreamSource.connect(meter);
        return drawLoop(meter)
        // drawLoop(meter)
        // kick off the visual updating
    }, [createAudioMeter, drawLoop, audioContext])

    useEffect(() => {
        navigator.mediaDevices.getUserMedia({
            "audio": {
                "mandatory": {
                    "googEchoCancellation": "false",
                    "googAutoGainControl": "false",
                    "googNoiseSuppression": "false",
                    "googHighpassFilter": "false"
                },
                "optional": []
            },
        })
            .then(function (stream) {
                gotStream(stream)
            })
            .catch(function (err) {
                console.log(err)
            });
    }, [gotStream])

    return <div />

}

export default MicrophoneListener


