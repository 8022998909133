import create from 'zustand'

export const [useStore] = create(set => ({
    volume: 0,
    sound: JSON.parse(localStorage.getItem('sound')),
    phase: 'setting',
    meditation: false,
    online: true,
    getModus: 'calming',
    breathDuration: 4000,
    mood: undefined,
    page: 0,
    fullScreen: false,
    section: 'Meet Ohm',
    enter: true,
    theta: {
        stop: undefined,
        play: undefined
    },
    setPage: page => {
        set({ page: page })
    },
    setSound: sound => {
        set({ sound: sound })
    },
    setEnter: enter => {
        set({ enter: enter })
    },
    setSection: section => {
        set({ section: section })
    },
    setFullScreen: fullScreen => {
        set({ fullScreen: fullScreen })
    },
    setTheta: theta => {
        set({ theta: theta })
    },
    setMood: mood => {
        set({ mood: mood })
    },
    setBreathDurarion: breathDuration => {
        set({ breathDuration: breathDuration })
    },
    setVolume: volume => {
        set({ volume: volume })
    },
    setModus: getModus => {
        set({ getModus: getModus })
    },
    setPhase: phase => {
        set({ phase: phase })
    },
    setMeditation: meditation => {
        set({ meditation: meditation })
    },
    setOnline: online => {
        set({ online: online })
    },
}))

export const [useBreathing] = create(set => ({
    breath: false,
    setBreath: breath => {
        set({ breath: breath })
    },
}))

export const [useStretching] = create(set => ({
    position: false,
    setPosition: position => {
        set({ position: position })
    },
}))
