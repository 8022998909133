import React from 'react'
import { stretch } from '../../textData/stretch'
import Text from '../../components/Text'
import { Grid } from '@material-ui/core'

const StretchingPage = ({ getModus }) => {
    return (
        <Grid style={{
            width: '100vw', height: '100vh', position: 'absolute', backgroundColor: 'black', overflowY: 'hidden'
        }} container justify='center' alignItems='center'>
            <Grid style={{ width: '100vw', position: 'absolute', height: '100vh' }} item xs={7}>
                <Text text={stretch} />
            </Grid>
        </Grid>
    )
}

export default StretchingPage
