import React, { useState } from 'react'
import { Grid } from '@material-ui/core'
import { Title } from '../components/styled'
import { useStore } from '../store'
import Orb from '../components/Orb'

const EnterPage = ({ audioContext, playGong, playTheta }) => {
    const [hover, set] = useState(false)
    const { setEnter, getModus } = useStore()

    return (
        <>
            <Grid container style={{ width: '100vw', height: '100vh', position: 'fixed', zIndex: 3, backgroundColor: 'rgba(0,0,0,0)' }} justify='center' alignItems='center'>
                <Orb hoverOrb={hover} type='hover' getModus={getModus} />
            </Grid>
            <div style={{ backgroundColor: 'black', width: '100vw', height: '100vh', overflowY: 'hidden' }}>
                <Grid container style={{ height: '100vh' }} justify='center' alignItems='center'>
                    <Grid style={{ display: 'flex', justifyContent: 'center' }} item xs={12}>
                        <div onPointerOver={() => set(true)} onPointerOut={() => set(false)} onClick={() => {
                            audioContext.resume()
                            playGong()
                            playTheta()
                            setEnter(false)
                        }} style={{ zIndex: 100, height: '300px', width: '300px', border: 'white 1px solid', display: 'table', borderRadius: '50%', cursor: 'pointer' }}>
                            <Title textAlign='center' style={{ display: 'table-cell', verticalAlign: 'middle' }}>Enter</Title>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </>
    )
}

export default EnterPage
