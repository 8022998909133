import React, { useEffect, useState } from 'react'
import { useMediaQuery, useTheme, Grid } from '@material-ui/core'
import { useLocation } from 'react-router-dom'
import { useStore } from '../../store'
import IntroSection from './IntroSection'
import OfficeSection from './OfficeSection'
import CreditSection from './CreditsSection'
import Orb from '../../components/Orb'
import { useGesture } from 'react-use-gesture'
import useWindowDimensions from '../../helperfunctions/useDimensions'
import Pagination from '../../components/Pagination'
import SectionName from '../../components/SectionName'
import EnterPage from '../EnterPage'

const LandingPage = ({ volume, audioContext, playGong, playTheta, stop }) => {
    const { height } = useWindowDimensions()
    const themes = useTheme();
    const small = useMediaQuery(themes.breakpoints.down('sm'));
    const location = useLocation()
    const { setModus, getModus, setPage, setSection, enter, sound } = useStore()

    const [scrollValue, set] = useState(0)
    const bind = useGesture(
        {
            onScroll: ({ values }) => set(values[1]),
        },
        { domTarget: window }
    )
    useEffect(bind, [bind]);

    useEffect(() => {
        if (scrollValue >= (height * 0.5) && scrollValue < (height * 1.5)) {
            setPage(1)
            setSection('Meet Ohm')
        } else if (scrollValue >= (height * 1.5) && scrollValue < (height * 2.5)) {
            setPage(2)
            setModus('calming')
            setSection('Meet Ohm')
        } else if (scrollValue >= (height * 2.5) && scrollValue < (height * 3.5)) {
            setPage(3)
            setSection('Features')
        } else if (scrollValue >= (height * 3.5) && scrollValue < (height * 4.5)) {
            setPage(4)
            setModus('activating')
            setSection('Features')
        } else if (scrollValue >= (height * 4.5) && scrollValue < (height * 5.5)) {
            setPage(5)
            setSection('Ohm for Office')
        } else if (scrollValue >= (height * 5.5)) {
            setPage(6)
            setSection('Credits')
        }
    }, [scrollValue, height, setModus, setPage, setSection])

    useEffect(() => {
        document.body.style.overflowY = location.pathname === '/ohm' ? 'hidden' : "scroll"
    }, [location.pathname])

    return (
        enter ?
            <EnterPage playGong={playGong} playTheta={playTheta} audioContext={audioContext} />
            :
            <>
                <Grid container style={{ width: '100vw', height: '100vh', position: 'fixed', zIndex: 3, backgroundColor: 'black' }} justify='center' alignItems='center'>
                    <Orb type='nonInteractive' scrollValue={scrollValue} nonInteractive getModus={getModus} volume={volume} />
                </Grid>
                <Pagination scrollValue={scrollValue} />
                <SectionName scrollValue={scrollValue} />
                <IntroSection playTheta={playTheta} stop={stop} volume={volume} audioContext={audioContext} location={location} small={small} getModus={getModus} />
                <OfficeSection />
                <CreditSection small={small} />
            </>
    )
}

export default LandingPage