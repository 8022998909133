import React from 'react'
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";

import App from './App';
import LandingPage from './pages/landingSections/LandingPage'
import MicrophoneListener from './helperfunctions/MicrophoneListener';
import useSound from 'use-sound'
import bowlSfx from './sounds/Sound-bowl.mp3'
import thetaSfx from './sounds/Theta-test.wav'
import { useStore } from './store';
import withoutSound from './images/Without-sound.svg'
import withSound from './images/With-sound.svg'

const audioContext = new AudioContext();

const Root = () => {
    const { sound } = useStore()

    const [playGong] = useSound(bowlSfx, {
        volume: sound ? 0.28 : 0
    });

    const [playTheta, { stop }] = useSound(thetaSfx, {
        volume: sound ? 0.28 : 0,
        loop: true
    });

    return (
        <Router>
            <MicrophoneListener audioContext={audioContext} />
            <img style={{ visibility: 'hidden', position: 'absolute', top: '-999em' }} alt='null' src={withoutSound} />
            <img style={{ visibility: 'hidden', position: 'absolute', top: '-999em' }} alt='null' src={withSound} />
            <Switch>
                <Route path="/ohm">
                    <App playGong={playGong} playTheta={playTheta} stop={stop} />
                </Route>
                <Route path="/">
                    <LandingPage playGong={playGong} playTheta={playTheta} stop={stop} audioContext={audioContext} />
                </Route>
            </Switch >
        </Router >
    )
}

export default Root
