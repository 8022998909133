export const welcome = [
    {
        text: 'Welcome in my monastery',
        duration: 4000
    },
    {
        text: 'My name is Ohm',
        duration: 3000
    },
    {
        text: 'I am your digital meditation leader',
        duration: 4000
    },
    {
        text: 'To get you in the right mindset, we are going to start with a simple exercise',
        duration: 5000
    },
    {
        text: 'After that, you can take as much time as you need to feed your mind',
        duration: 6000,
        setModus: 'activating'
    },
    {
        text: 'If you want to stop, go back online',
        duration: 6000,
        setModus: 'activating'
    },
    {
        text: 'We will start in:',
        duration: 0,
        setModus: 'calming'
    },
];