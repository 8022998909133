import React, { useState } from 'react'
import { Grid, useTheme, useMediaQuery } from '@material-ui/core'
import Logo from '../components/Logo'
import { useTransition, a } from 'react-spring'
import { useStore } from '../store'
import SettingPage from './SettingPage'
import { Text } from '../components/styled'
import '../App.css'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import Fade from 'react-reveal/Fade';
import Button from '../components/Button'

const SacrificePage = ({ online }) => {
    const themes = useTheme();
    const small = useMediaQuery(themes.breakpoints.down('sm'));
    const { phase } = useStore()

    return (
        <>
            <Grid style={{ padding: small ? 30 : 60, position: 'absolute' }} container>
                <Grid item xs={12}>
                    <Grid style={{ zIndex: 100 }} container justify='space-between'>
                        <Logo style={{ height: '150px' }} small={small} dark />
                        <Link style={{ textDecoration: 'none', zIndex: 100 }} to='/'>
                            <Text style={{ margin: 0, cursor: 'pointer' }} color='black'>Back</Text>
                        </Link>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container alignItems='center' style={{ height: '100vh' }}>
                <Grid item>
                    {
                        phase === 'setting' ?
                            <SettingPage />
                            :
                            <Disable online={online} />
                    }
                </Grid>
            </Grid>
        </>
    )
}

function Disable({ online }) {
    const [choose, setChoose] = useState(false)
    const { setMood } = useStore()

    const relaxedSequences = { name: 'relaxing', sequences: ['breathing', 'silence', 'grateful', 'stretching'] }
    const energizeSequences = { name: 'energizing', sequences: ['breathing'] }
    const concentrateSequences = { name: 'concentrating', sequences: ['focusing'] }
    const reflectSequences = { name: 'reflecting', sequences: ['want', 'joy', 'grateful'] }

    const sequences = [relaxedSequences, energizeSequences, concentrateSequences, reflectSequences]

    useEffect(() => {
        const randomSequence = sequences[Math.floor(Math.random() * sequences.length)]
        const randomRandomSequence = randomSequence?.sequences[Math.floor(Math.random() * randomSequence?.sequences.length)]
        setMood([randomRandomSequence])
        console.log('1')
    }, [setMood])

    useEffect(() => {
        if (online === false) setTimeout(() => {
            setChoose(true)
        }, 5000);
    }, [online])

    const transitions = useTransition(online, null, {
        from: {
            top: 0,
            opacity: 0,
            position: 'absolute',
            width: '100%',
            height: '100vh',
        },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
    })

    return (
        transitions.map(({ item, key, props }) => {
            return (
                !item
                    ? <a.div key={key} style={props}>
                        <Grid item xs={12} style={{ height: '100%' }}>
                            <Grid justify='center' style={{ height: '100%', width: '100vw' }} alignItems='center' container>
                                <Fade bottom duration={2000} distance={'1rem'}>
                                    <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center' }}>
                                        <Grid item xs={12} style={{ height: '100%', display: 'table', width: '100vw' }}>
                                            {choose ?
                                                <Text textAlign='center' color=' black' style={{ display: 'table-cell', verticalAlign: 'middle' }}>
                                                    Thanks for the sacrifice. <br />
                                                    To continue Toggle fullscreen <br /> <br />
                                                    <Button full={true} color='black' text='Toggle fullscreen' />
                                                </Text>
                                                :
                                                <Text textAlign='center' color=' black' style={{ display: 'table-cell', verticalAlign: 'middle' }}>
                                                    Let go of all the distractions and focus on your mind
                                                </Text>
                                            }
                                        </Grid>
                                    </div>
                                </Fade>
                            </Grid>
                        </Grid>
                    </a.div>
                    : <a.div key={key} style={props}>
                        <Grid item xs={12} style={{ height: '100%' }}>
                            <Grid justify='center' style={{ height: '100%', width: '100vw' }} alignItems='center' container>
                                <Fade bottom duration={2000} distance={'1rem'}>
                                    <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center' }}>
                                        <Grid item xs={12} style={{ height: '100vh', display: 'table', width: '100vw' }}>
                                            <Text textAlign='center' color=' black' style={{ display: 'table-cell', verticalAlign: 'middle' }}>
                                                Disable your internet connection <br />
                                            to enter the digital monastery. <br /> <br />
                                            </Text>
                                        </Grid>
                                    </div>
                                </Fade>
                            </Grid>
                        </Grid>
                    </a.div>
            )
        })

    )
}

export default SacrificePage
