export const breathing = [
    {
        text: 'Let’s start with a breathing exercise',
        duration: 5000,
        setModus: 'extra-calming'
    },
    {
        text: 'Follow my cue',
        duration: 3000
    },
    // -----
    {
        text: 'Breath in',
        direction: 'Breath in',
        duration: 4000
    },
    {
        text: 'Hold your breath',
        direction: 'Hold your breath',
        duration: 1000
    },
    {
        text: 'Breath out',
        direction: 'Breath out',
        duration: 6000
    },
    // -----
    {
        text: 'Breath in',
        direction: 'Breath in',
        duration: 4000
    },
    {
        text: 'Hold your breath',
        direction: 'Hold your breath',
        duration: 1000
    },
    {
        text: 'Breath out',
        direction: 'Breath out',
        duration: 6000
    },
    // -----
    {
        text: 'Continue',
        direction: 'Breath in',
        duration: 4000,
    },
    {
        text: 'Continue',
        direction: 'Hold your breath',
        duration: 2000,
    },
    {
        text: 'Continue',
        direction: 'Breath out',
        duration: 6000,
    },
]
