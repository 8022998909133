import styled from 'styled-components'
import { a } from 'react-spring'

export const Title = styled.h1`
    font-size: calc(1.8rem + 0.4vw);
    line-height: 1.1;
    word-break: keep-all;
    font-family: 'graphik';
    font-weight: 400;
    color: ${props => props.color || '#fff'};
    margin-bottom: ${props => props.marginBottom || ''};
    text-align: ${props => props.textAlign || ''};
`;

export const SubTitle = styled.h2`
    font-size: calc(1.3rem + 0.6vw);
    line-height: 1.3;
    word-break: keep-all;
    font-family: 'graphik';
    font-weight: 400;
    color: ${props => props.color || '#fff'};
    margin-bottom: ${props => props.marginBottom || ''};
    text-align: ${props => props.textAlign || ''};
`;

export const Text = styled(a.p)`
    font-size: calc(0.8rem + 0.6vw);
    line-height: 1.3;
    word-break: keep-all;
    font-family: 'graphik';
    font-weight: 400;
    color: ${props => props.color || '#fff'};
    margin-bottom: ${props => props.marginBottom || ''};
    text-align: ${props => props.textAlign || ''};
`;


export const PageWrapper = styled.div`
    width: 100vw;
    height: ${props => props.height || '100vh'};
    z-index: 3;
    overflow-x: hidden;
    position: relative;
    overflow-y: hidden;
`;