import React, { useState } from 'react'
import { Grid, useMediaQuery, useTheme } from '@material-ui/core'
import Logo from '../components/Logo'
import { useSpring, a } from 'react-spring'
import { useStore } from '../store'
import useSound from 'use-sound';
import bowlSfx from '../sounds/Sound-bowl.mp3';
import { Text } from '../components/styled'
import { Link } from 'react-router-dom'
import Fade from 'react-reveal/Fade';

const SummonOhm = () => {
    const themes = useTheme();
    const small = useMediaQuery(themes.breakpoints.down('sm'));
    let [width, setWidth] = useState(0)
    let [bar, setBar] = useState(true)
    const { setPhase, volume, sound } = useStore()
    const [playGong] = useSound(bowlSfx, {
        volume: sound ? 0.28 : 0
    });

    const add = () => {
        if (volume >= 4 && bar) {
            if (Math.round(width) < 105) {
                setWidth(width += 0.165 * 3)
            } else {
                cancelAnimationFrame(animation)
                setWidth(100)
                setBar(false)
                playGong()
                setPhase('welcome')
            }
        } else if (bar) setWidth(0)

    }

    const animation = requestAnimationFrame(add)

    const props = useSpring({
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100vh',
        width: `${width}%`,
        backgroundColor: 'black',
        config: {
            tension: 210,
            friction: 20
        }
    })

    return (
        <>
            <Grid item xs={12}>
                <a.div style={props}></a.div>
            </Grid>
            <Grid style={{ padding: small ? 30 : 60, height: '100vh' }} container>
                <Grid item xs={12}>
                    <Grid container justify='space-between'>
                        <Logo style={{ height: '150px' }} small={small} dark />
                        <Link style={{ textDecoration: 'none' }} to='/'>
                            <Text style={{ margin: 0, cursor: 'pointer' }} color='black'>Back</Text>
                        </Link>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Fade bottom delay={100} duration={2000} distance={'1rem'}>
                        <Grid justify='center' alignItems='center' container>
                            <Grid item xs={4}>
                                <Text textAlign='center' color=' black'>
                                    Enter by chanting: <br />
                                Ohmmm…
                            </Text>
                            </Grid>
                        </Grid>
                    </Fade>
                </Grid>
            </Grid>
        </>
    )
}

export default SummonOhm
