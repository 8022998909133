import React, { useEffect, useState } from 'react';
import { Detector } from "react-detect-offline";
import { Grid, IconButton } from '@material-ui/core';
import { useLocation, useHistory } from 'react-router-dom';
import Fullscreen from "react-full-screen";
import { theme } from './theme/theme';
import { useSpring, a } from 'react-spring';

import { useMove } from 'react-use-gesture';
import { GetPageOffline } from './helperfunctions/GetPageOffline';
import { GetPageOnline } from './helperfunctions/GetPageOnline';
import { useStore } from './store';
import Orb from './components/Orb';
import './App.css';
import { Text } from './components/styled';
import withoutSound from './images/Without-sound.svg'
import withSound from './images/With-sound.svg'

const App = ({ volume, playTheta, playGong, stop }) => {
  const { phase, meditation, getModus, fullScreen, setPhase, setMeditation, sound, setSound } = useStore()
  const [mouseMoving, setMouseMoving] = useState()
  const location = useLocation()
  const history = useHistory()
  const bind = useMove((state) => {
    if (!state.moving) {
      setTimeout(() => {
        setMouseMoving(state.moving)
      }, 2000);
    }
    else {
      setMouseMoving(state.moving)
    }
  })

  useEffect(() => {
    document.body.style.cursor = getCursor()
  })

  const getCursor = () => {
    if (!mouseMoving && meditation && phase !== 'summon') return 'none'
    else return 'default'
  }

  useEffect(() => {
    document.body.style.overflowY = location.pathname === '/ohm' ? 'hidden' : "scroll"
  }, [location.pathname])

  const props = useSpring({
    opacity: mouseMoving ? 1 : 0,
    marginRight: 32
  })

  const props2 = useSpring({
    opacity: mouseMoving ? 1 : 0,
  })

  return (
    <div {...bind()} className='meditation' style={{ backgroundColor: theme.colors.background, overflowY: 'hidden', height: '100vh', width: '100vw' }}>
      {/* <ReactNoSleep> */}
      <Fullscreen
        enabled={fullScreen}
      >
        <Detector
          render={({ online }) => {
            if (!meditation) {
              return <GetPageOnline online={online} volume={volume} phase={phase} />
            } else if (!online && meditation) {
              return (
                <>
                  {phase !== 'summon' &&
                    <>
                      <Grid style={{ backgroundColor: 'rgba(0,0,0,0)', position: 'absolute', zIndex: 10 }} container alignItems='center' justify='flex-end'>
                        <Grid item style={{ cursor: 'pointer' }}>
                          <a.div style={props2}>
                            <IconButton onClick={() => {
                              const soundLocal = JSON.parse(localStorage.getItem('sound'))
                              const newSound = JSON.stringify(!soundLocal)
                              localStorage.setItem('sound', newSound);
                              !sound ? playTheta() : stop()
                              setSound(!sound)
                            }} style={{ border: 'none', borderRadius: '50%', width: 44.5, height: 44.5, padding: 0, marginRight: 20 }}>
                              {sound ?
                                <img alt='sound on' style={{ height: '40px' }} src={withSound} />
                                :
                                <img alt='sound off' style={{ height: '40px' }} src={withoutSound} />
                              }
                            </IconButton>
                          </a.div>
                        </Grid>
                        <Grid onClick={() => {
                          setPhase(undefined)
                          setMeditation(false)
                          history.push('/')
                        }} item style={{ cursor: 'pointer' }}>
                          <Text style={props}>Stop</Text>
                        </Grid>
                      </Grid>
                      <div style={{ width: '100vw', height: '100vh', position: 'absolute', margin: 'auto' }}>
                        <Orb scrollValue={0} mouse={[0, 0]} getModus={getModus} volume={volume} />
                      </div>
                    </>
                  }
                  <GetPageOffline getModus={getModus} volume={volume} phase={phase} />
                </>
              )
            } else if (online) {
              history.push('/')
              return null
            }
          }}
        />
      </Fullscreen>
      {/* </ReactNoSleep> */}
    </div >
  );
}

export default App;