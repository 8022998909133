import React from 'react'
import { Button as MatButton, Typography } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { useStore } from '../store'

const Button = ({ text, color, size, link, audioContext, full, stat }) => {
    const history = useHistory()
    const { setMeditation, setPhase } = useStore()
    const elem = document.documentElement;

    return (
        <MatButton

            onClick={() => {
                if (full) {
                    if (elem.requestFullscreen) {
                        elem.requestFullscreen();
                    } else if (elem.mozRequestFullScreen) { /* Firefox */
                        elem.mozRequestFullScreen();
                    } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
                        elem.webkitRequestFullscreen();
                    } else if (elem.msRequestFullscreen) { /* IE/Edge */
                        elem.msRequestFullscreen();
                    }
                    setMeditation(true)
                    setPhase('summon')
                }
                if (link) history.push(link)
                if (audioContext) {
                    audioContext.resume()
                }
            }}
            style={{
                borderRadius: 50,
                borderWidth: 1,
                borderColor: color,
                paddingTop: 4,
                paddingBottom: 4,
                paddingRight: 20,
                paddingLeft: 20,
                textTransform: 'none',
            }}
            variant='outlined'
            size={size}
        >
            <Typography style={{
                fontWeight: 400,
                fontSize: stat ? 20 : 'calc(0.8rem + 0.6vw)',
                fontFamily: 'Graphik',
                color: color,
            }}>{text}</Typography>
        </MatButton>
    )
}

export default Button
