export const silence = [
    {
        text: 'Let’s focus on your surroundings for a moment',
        duration: 7000,
        soundOff: true,
        setModus: 'extra-calming'
    },
    {
        text: 'Close your eyes…',
        duration: 4000
    },
    {
        text: 'and listen to the sounds around you',
        duration: 5000
    },
    {
        text: '',
        duration: 180000
    },
];
