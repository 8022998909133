import React from 'react'
import SettingPage from "../pages/SettingPage";
import SacraficePage from "../pages/SacraficePage";

export const GetPageOnline = ({ phase, online, volume }) => {
    switch (phase) {
        case 'setting':
            return <SettingPage />
        case 'sacrifice':
            return <SacraficePage gvolume={volume} online={online} />
        default:
            return <SacraficePage gvolume={volume} online={online} />
    }

}