export const stretch = [
    {
        text: 'Let’s start by stretching our necks',
        duration: 7000
    },
    {
        text: 'If you are sitting or laying down please stand',
        duration: 7000
    },
    {
        text: 'Feel the weight of your head on your shoulders',
        duration: 7000
    },
    {
        text: 'Breath in',
        direction: 'Breath in',
        duration: 5000
    },
    {
        text: 'While you breath out relax your shoulders',
        direction: 'Breath out',
        duration: 8000
    },
    {
        text: 'Follow my instructions by moving your head.',
        duration: 6000
    },
    {
        text: 'Look to your…',
        duration: 3000
    },
    // ------
    {
        text: 'Front',
        direction: 'Front',
        duration: 4000
    },
    {
        text: 'Left',
        direction: 'Left',
        duration: 4000
    },
    {
        text: 'Front',
        direction: 'Front',
        duration: 4000
    },
    {
        text: 'Right',
        direction: 'Right',
        duration: 4000
    },
    // ------
    {
        text: 'continue',
        direction: 'Front',
        duration: 4000
    },
    {
        text: 'continue',
        direction: 'Left',
        duration: 6000
    },
    {
        text: 'continue',
        direction: 'Front',
        duration: 6000
    },
    {
        text: 'continue',
        direction: 'Right',
        duration: 6000
    },

    {
        text: 'Look at your…',
        playGong: true,
        duration: 6000
    },
    // ------
    {
        text: 'Ceiling',
        direction: 'Ceiling',
        duration: 6000
    },
    {
        text: 'Front',
        direction: 'Front',
        duration: 6000
    },
    {
        text: 'Floor',
        direction: 'Floor',
        duration: 6000
    },
    {
        text: 'Front',
        direction: 'Front',
        duration: 6000
    },
    // ------
    {
        text: 'Continue',
        direction: 'Ceiling',
        duration: 6000,
    },
    {
        text: 'Continue',
        direction: 'Front',
        duration: 6000
    },
    {
        text: 'Continue',
        direction: 'Floor',
        duration: 6000,
    },
    {
        text: 'Continue',
        direction: 'Front',
        duration: 6000
    },


    {
        text: 'Look to your…',
        duration: 4000
    },
    // ------
    {
        text: 'Front',
        direction: 'Front',
        duration: 6000
    },
    {
        text: 'Left',
        direction: 'Left',
        duration: 6000
    },
    {
        text: 'Front',
        direction: 'Front',
        duration: 6000
    },
    {
        text: 'Right',
        direction: 'Right',
        duration: 6000
    },
    // ------
    {
        text: 'continue',
        direction: 'Front',
        duration: 6000
    },
    {
        text: 'continue',
        direction: 'Left',
        duration: 6000
    },
    {
        text: 'continue',
        direction: 'Front',
        duration: 6000
    },
    {
        text: 'continue',
        direction: 'Right',
        duration: 6000
    },

    {
        text: 'Look at your…',
        playGong: true,
        duration: 6000
    },
    // ------
    {
        text: 'Ceiling',
        direction: 'Ceiling',
        duration: 6000
    },
    {
        text: 'Front',
        direction: 'Front',
        duration: 6000
    },
    {
        text: 'Floor',
        direction: 'Floor',
        duration: 6000
    },
    {
        text: 'Front',
        direction: 'Front',
        duration: 6000
    },
    // ------
    {
        text: 'Continue',
        direction: 'Ceiling',
        duration: 6000,
    },
    {
        text: 'Continue',
        direction: 'Front',
        duration: 6000
    },
    {
        text: 'Continue',
        direction: 'Floor',
        duration: 6000,
    },
    {
        text: 'Continue',
        direction: 'Front',
        duration: 6000
    },
]