import React from 'react'
import SummonOhm from "../pages/SummonOhm";
import WelcomePage from "../pages/meditationSections/WelcomePage";
import BreathingPage from '../pages/meditationSections/BreathingPage';
import StretchingPage from '../pages/meditationSections/StretchingPage';
import FocusingPage from '../pages/meditationSections/FocusingPage';
import EndingPage from '../pages/meditationSections/EndingPage';
import Want from '../pages/meditationSections/WantPage';
import Joy from '../pages/meditationSections/JoyPage';
import Grateful from '../pages/meditationSections/GratefulPage';
import Silence from '../pages/meditationSections/SilencePage';

export const GetPageOffline = ({ phase, volume }) => {
    switch (phase) {
        case 'summon':
            return <SummonOhm volume={volume} />
        case 'sacrifice':
            return <SummonOhm volume={volume} />
        case 'welcome':
            return <WelcomePage volume={volume} />
        case 'breathing':
            return <BreathingPage volume={volume} />
        case 'stretching':
            return <StretchingPage volume={volume} />
        case 'focusing':
            return <FocusingPage volume={volume} />
        case 'want':
            return <Want volume={volume} />
        case 'joy':
            return <Joy volume={volume} />
        case 'grateful':
            return <Grateful volume={volume} />
        case 'silence':
            return <Silence volume={volume} />
        case 'end':
            return <EndingPage volume={volume} />
        default:
            break;
    }

}