import React from 'react'
import { Text } from '../components/styled'
import { useSpring } from 'react-spring'
import { useStore } from '../store'
import useWindowDimensions from '../helperfunctions/useDimensions'

const SectionName = ({ scrollValue }) => {
    const { section } = useStore()
    const { height } = useWindowDimensions()
    const props = useSpring({
        opacity: scrollValue < (height * 0.5) ? 0 : 1,
        width: '145px',
        whiteSpace: 'nowrap', position: 'fixed', zIndex: '12', top: `${50}%`,
        transform: scrollValue < (height * 0.5) ? 'translateX(50px) rotate(90deg)' : 'translateX(0px) rotate(90deg)',
        margin: 0,
        textAlign: 'center',
        config: {
            mass: 20,
            tension: 418,
            friction: 180
        }
    })

    return (
        <div style={{ position: 'absolute', height: '100vh', top: '50%', right: '100px' }}>
            <Text style={props}>
                {section}
            </Text>
        </div>
    )
}

export default SectionName;