import React from 'react'
import { Grid, IconButton } from '@material-ui/core'
import Button from '../../components/Button'
import scrollIcon from '../../images/scrollIconDown.png'
import Fade from 'react-reveal/Fade';
import { Title, Text, SubTitle, PageWrapper } from '../../components/styled'
import Logo from '../../components/Logo';
import { useStore } from '../../store';
import withoutSound from '../../images/Without-sound.svg'
import withSound from '../../images/With-sound.svg'

const IntroSection = ({ audioContext, small, stop, playTheta }) => {
    const { sound, setSound } = useStore()

    return (
        <PageWrapper height='500vh'>
            <Grid style={{ padding: small ? 30 : 60, height: '100vh' }} container>
                <Grid style={{ position: 'fixed', width: '100vw', margin: 'auto', zIndex: 10 }} item xs={12}>
                    <Fade top delay={500} duration={1500} distance={'1rem'}>
                        <Grid container justify='space-between'>
                            <Grid item>
                                <Logo style={{ height: '150px' }} small={small} light />
                            </Grid>
                            <Grid style={{ right: small ? 60 : 103, top: -18, position: 'absolute' }} item>
                                <IconButton onClick={() => {
                                    const soundLocal = JSON.parse(localStorage.getItem('sound'))
                                    const newSound = JSON.stringify(!soundLocal)
                                    localStorage.setItem('sound', newSound);
                                    !sound ? playTheta() : stop()
                                    setSound(!sound)
                                }} >
                                    {sound ?
                                        <img alt='sound on' style={{ height: '40px' }} src={withSound} />
                                        :
                                        <img alt='sound off' style={{ height: '40px' }} src={withoutSound} />
                                    }
                                </IconButton>
                                <Button stat audioContext={audioContext} link='/ohm' icon text='Summon Ohm' color='white' />
                            </Grid>
                        </Grid>
                    </Fade>
                </Grid>
                <Fade bottom delay={1500} duration={2000} distance={'1rem'}>
                    <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center' }}>
                        <Grid style={{ zIndex: 3, textAlign: small ? 'center' : 'left' }} item xs={12}>
                            <Title marginBottom='40'>
                                Ohm is your digital<br />
                                meditation leader
                            </Title>
                            <Fade bottom delay={2000} duration={2000} distance={'1rem'}>
                                <Text marginBottom='40'>
                                    Transform your room into a<br />
                                digital monastery where you<br />
                                can focus on your mind
                            </Text>
                            </Fade>
                            <Fade bottom delay={3000} duration={2000} distance={'1rem'}>
                                <Text marginBottom='40'>
                                    {!small && 'Explore'}  {<img alt='scroll icon' src={scrollIcon} style={{ fontSize: '1vw', marginLeft: 10, height: '12px' }} />}
                                </Text>
                            </Fade>
                        </Grid>
                    </div>
                </Fade>
            </Grid>

            <Grid style={{ padding: small ? 30 : 60, height: '100vh' }} container>
                <Fade bottom delay={0} duration={1500} distance={'1rem'}>
                    <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Grid style={{ zIndex: 3, textAlign: 'center' }} item xs={12} md={6}>
                            <Fade bottom duration={1500} distance={'1rem'}>
                                <SubTitle>
                                    A digital monastery
                                </SubTitle>
                                <Title marginBottom='40'>
                                    Ohm is an digital being that creates a space
                                    between the virtual and physical. Away from
                                    the distractions of the online world.
                                </Title>
                            </Fade>
                        </Grid>
                    </div>
                </Fade>
            </Grid>

            <Grid style={{ padding: small ? 30 : 60, height: '100vh' }} container>
                <Fade bottom delay={0} duration={1500} distance={'1rem'}>
                    <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Grid style={{ zIndex: 3, textAlign: 'center' }} item xs={12} md={6}>
                            <Fade bottom duration={1500} distance={'1rem'}>
                                <SubTitle>
                                    Enter the monastery
                                </SubTitle>
                                <Title marginBottom='40'>
                                    Connect your device to your TV, go offline and
                                    transform your space into a digital monastery
                                </Title>
                            </Fade>
                        </Grid>
                    </div>
                </Fade>
            </Grid>


            <Grid style={{ padding: small ? 30 : 60, height: '100vh' }} container>
                <Fade bottom delay={0} duration={1500} distance={'1rem'}>
                    <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Grid style={{ zIndex: 3, textAlign: 'center' }} item xs={12} md={6}>
                            <Fade bottom duration={1500} distance={'1rem'}>
                                <SubTitle>
                                    Ohm hears you
                                </SubTitle>
                                <Title marginBottom='40'>
                                    Interact with Ohm using your
                                    (build-in) microphone.
                                </Title>
                            </Fade>
                        </Grid>
                    </div>
                </Fade>
            </Grid>

            <Grid style={{ padding: small ? 30 : 60, height: '100vh' }} container>
                <Fade bottom delay={0} duration={1500} distance={'1rem'}>
                    <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Grid style={{ zIndex: 3, textAlign: 'center' }} item xs={12} md={6}>
                            <Fade bottom duration={1500} distance={'1rem'}>
                                <SubTitle>
                                    Ohm feels your energy
                                 </SubTitle>
                                <Title marginBottom='40'>
                                    Ohm changes its colors to amplify your
                                    meditation using chromotherapy.
                                 </Title>
                            </Fade>
                        </Grid>
                    </div>
                </Fade>
            </Grid>

        </PageWrapper>

    )
}
export default IntroSection
