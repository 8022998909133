export const focus = [
    {
        text: 'Let’s start by training our focus',
        duration: 7000,
        setModus: 'activating'
    },
    {
        text: 'Keep your focus on me',
        duration: 4000
    },
    {
        text: 'If your thoughts wander',
        duration: 3000
    },
    {
        text: 'Gently guide your focus back again',
        duration: 4000
    },
    {
        text: '',
        duration: 180000
    },
];
