import React from 'react'
import { Grid } from '@material-ui/core'
import Fade from 'react-reveal/Fade';
import { Title, SubTitle } from '../../components/styled';
import Button from '../../components/Button'

const OfficeSection = ({ small }) => {
    return (
        <div style={{ width: '100vw', height: '100vh', backgroundColor: 'black' }}>
            <Grid style={{ padding: 30, height: '100vh' }} alignItems='center' justify='center' container direction='row'>
                <Fade bottom delay={0} duration={2000} distance={'1rem'}>
                    <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', zIndex: 3, justifyContent: 'center' }}>
                        <Grid style={{ zIndex: 3, textAlign: 'center' }} item xs={12} md={6} >
                            <SubTitle>
                                Hire Ohm as well-being officer
                            </SubTitle>
                            <Title marginBottom='40'>
                                Regular meditation at work can have many benefits.
                                See what Ohm can do for you.
                            </Title>
                            <Button text='Coming soon' color='white' />
                        </Grid>
                    </div>
                </Fade>
            </Grid>
        </div>
    )
}

export default OfficeSection
