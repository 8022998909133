import React from 'react'
import logoDark from '../images/ohmLogoDark.svg'
import logoLight from '../images/ohmLogoLight.svg'
import { useHistory } from 'react-router-dom'

const Logo = ({ light, small }) => {
    const history = useHistory()

    return (
        <div onClick={() => history.push('/')} style={{ zIndex: 5, height: '100%', cursor: 'pointer' }}>
            <img alt='logo' width={90} src={light ? logoLight : logoDark} />
        </div>
    )
}

export default Logo
